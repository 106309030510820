const personalityQuizAnswers = [
    {
        "value" : 1,
        "name" : "Strongly Dislike"
     },
     {
        "value" : 2,
        "name" : "Dislike"
     },
     {
        "value" : 3,
        "name" : "Unsure"
     },
     {
        "value" : 4,
        "name" : "Like"
     },
     {
        "value" : 5,
        "name" : "Strongly Like"
     }
];
export default personalityQuizAnswers;