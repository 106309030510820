

import React, { useState } from 'react';
import './Form.css'; 

function Form({ onSubmit }) {
  const [universityYear, setUniversityYear] = useState('');
  const [careerPath, setCareerPath] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (universityYear && careerPath) {
      onSubmit({ universityYear, careerPath });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>University Year:</label>
        <input
          type="text"
          value={universityYear}
          onChange={(e) => setUniversityYear(e.target.value)}
          placeholder="Enter your university year"
        />
      </div>
      <div>
        <label>Passion (Career Path):</label>
        <input
          type="text"
          value={careerPath}
          onChange={(e) => setCareerPath(e.target.value)}
          placeholder="Enter your career path"
        />
      </div>
      <button type="submit">Generate Roadmap</button>
    </form>
  );
}

export default Form;
