

import React from 'react';

function GoogleSheetEmbed({ style }) {
  const sheetUrl = "https://docs.google.com/spreadsheets/d/e/2PACX-1vQBOpsK9dbEmnxKJiHMF1unZ-Lf5RewlNsRUDU8tkcFKgePHIlKYoCy6TxiuH8H7oEAp9jql1fQeSHv/pubhtml"; // Replace with your actual embed URL

  return (
    <div style={{ ...style, width: '100%' }}>
      <iframe
        src={sheetUrl}
        style={{
          width: '100%',
          height: '600px', // Default height for larger screens
          border: 'none',
          maxWidth: '100%',
        }}
        title="Google Sheet"
      ></iframe>

      <style jsx>{`
        /* Media Query for mobile devices */
        @media only screen and (max-width: 768px) {
          iframe {
            height: 400px; /* Reduce the height for mobile */
          }
        }
        
        @media only screen and (max-width: 480px) {
          iframe {
            height: 300px; /* Further reduce the height for smaller screens */
          }
        }
      `}</style>
    </div>
  );
}

export default GoogleSheetEmbed;

