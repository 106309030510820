import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "./OpportunitiesPage.css";
import GoogleSheetEmbed from '../components/GoogleSheetEmbed';

function OpportunitiesPage() {
  return (
    <div className="App">
       <header style={{ padding: '60px', textAlign: 'center', margin:'5px'}}>
                <h1 className="some-class">Career Opportunities</h1> 
            </header>
            
            
            
           
            <div className='container-careers '>
              <GoogleSheetEmbed />
             </div>
        

    </div>
  );
}

export default OpportunitiesPage;

